<template>
  <div class="card">
    <div
      class="card-body p-0 d-flex flex-column justify-content-center align-items-center"
    >
      <a :class="{ selected: checked }" href="#" class="user-type-content">
        <div class="user-type-icon">
          <!--<v-icon :icon="['fac', icon]" /> -->
          <img
            v-if="!checked"
            :src="icon"
            style="width: 30px; height: 30px"
            alt=""
          />
          <img v-else :src="icon2" style="width: 30px; height: 30px" alt="" />
        </div>
        <h3 class="user-type-content-title">{{ title }}</h3>
        <p class="card-body-desc">{{ desc }}</p>
        <div class="is-select">
          <span class="circle">
            <span class="checked"></span>
          </span>

          <span class="text">{{ label }}</span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "user-card-type",
  props: {
    icon: null,
    icon2: null,
    title: null,
    desc: null,
    checked: false,
    label: null,
  },
};
</script>

<style>
</style>