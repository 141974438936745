<template>
    <!-- <div>
        <h3
            v-if="changingSteps == 1"
        >
            {{$t("auth.step1.sl-title")}}
        </h3>
        <h3
            v-else-if="changingSteps == 2"
        >
            {{$t("auth.step2.sl-title")}}
        </h3>

        <h3
            v-else-if="changingSteps == 3"
        >
            {{$t("auth.step3.sl-title")}}
        </h3>

        <h3
            v-else-if="changingSteps == 4"
        >
            {{$t("auth.step4.sl-title")}}
        </h3>

        <h3 v-else>
            {{$t("auth.step1.sl-title")}}
        </h3>

        <p
            v-if="changingSteps == 1"
        >
            {{$t("auth.step1.sl-p")}}
        </p>

        <p
            v-else-if="changingSteps == 2"
        >
            {{$t("auth.step2.sl-p")}}
        </p>

        <p
            v-else-if="changingSteps === 3"
        >
            {{$t("auth.step3.sl-p")}}
        </p>

        <p
            v-else-if="changingSteps === 4"
        >
            {{$t("auth.step4.sl-p")}}
        </p>

        <p v-else>
            {{$t("auth.step1.sl-p")}}
        </p>
    </div> -->
</template>
<script>
export default {
    data() {
        return {
            changingSteps: 1,
        };
    },
    computed: {

    },
    created() {
        if (this.$route.path.indexOf('step-1') > -1) {
            this.changingSteps = 1;
        } else if (this.$route.path.indexOf('step-2') > -1) {
            this.changingSteps = 2;
        } else if (this.$route.path.indexOf('step-3') > -1) {
            this.changingSteps = 3;
        } else {
            this.changingSteps = 4;
        }
    }
};
</script>