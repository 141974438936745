<template>
  <!--<div>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <loading :loading="loading" />
      <div v-if="!loading" class="step-content">
        <div
          class="container pt-5 mt-5"
          style="min-height: 120vh; max-height: 120vh"
        >
          <div class="row justify-content-center">
            <div
              v-for="(type, i) in types"
              :key="i"
              class="col-4 register-step-user-type"
            >
              <user-card
                @click.prevent.native="selectUserType(type.id)"
                :label="type.label"
                :title="type.name"
                :icon="type.icon"
                :desc="type.desc"
                :checked="selectedType === type.id"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <button
                style="position: fixed; right: 0; bottom: 0"
                @click="changeToStep3()"
                class="btn btn-primary"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </validation-observer> 

    
  </div>-->
  <!--jimir3x-->
  <div class="container">
    <!--jimir3x-->
    <step-header/>
    <loading :loading="loading" />
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <div v-if="!loading" class="step-content my-4 " style="">
        <div class="row">
          <div
            class="col-sm-6 my-2 register-step-user-type"
            v-for="(type, i) in types"
            :key="i"
          >
            <user-card
              @click.prevent.native="selectUserType(type.id)"
              :label="type.label"
              :title="type.name"
              :icon="type.icon"
              :icon2="type.icon2"
              :desc="type.desc"
              :checked="selectedType === type.id"
            />
          </div>
        </div>
      </div>

      <!-- <div
        class="form-group d-flex align-items-center justify-content-end step3-next-btn-bar zoom"
      >
        <button @click="changeToStep3()" class="btn btn-primary">
          {{ $t('buttons.next') }}
        </button>
      </div> -->
      <step-footer v-if="!loading" :disabled="!selectedType" @submit="handleSubmit(changeToStep3)" />
    </validation-observer>
  </div>
</template>

<script>
import loading from '../../components/global/loading';
import userCard from '@/components/global/register-steps/userTypeCard';
import stepFooter from '../../components/register-step/step-footer';
import checkStep from '@/graphql/auth/checkStep.gql';
import step3 from '@/graphql/auth/register/step3.graphql';
import { mapMutations, mapGetters } from 'vuex';
import StepHeader from '@/components/register-step/step-header.vue';
export default {
  components: {
    userCard,
    stepFooter,
    loading,
    StepHeader
  },
  data() {
    return {
      selectedType: false,
      loading: true,
      types: [
        {
          id: 'SELLER',
          name: this.$t('auth.step2.tab1-title'),
          icon: 'https://app.connectter.com/img/step2-seller-icon.svg',
          icon2: 'https://app.connectter.com/img/step2-seller-icon-blue.svg',
          desc: this.$t('auth.step2.tab1-text1'),
          checked: false,
          label: this.$t('auth.step2.tab1-text2'),
        },
        {
          id: 'BUYER',
          name: this.$t('auth.step2.tab2-title'),
          icon: 'https://app.connectter.com/img/step2-buyer-icon.svg',
          icon2: 'https://app.connectter.com/img/step2-buyer-icon-blue.svg',
          desc: this.$t('auth.step2.tab2-text1'),
          checked: false,
          label: this.$t('auth.step2.tab2-text2'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getEmail: 'general/getEmail',
      getStep: 'general/getCompleteStep',
      getUserType: 'general/getUserType',
    }),
  },
  methods: {
    ...mapMutations({
      setEmail: 'general/setEmail',
      SET_COMPLETE_STEP: 'general/setCompleteStep',
      SET_TOKEN: 'general/setToken',
      SET_USER_TYPE: 'general/SET_USER_TYPE',
    }),
    changeToStep3() {
      this.formSubmit();
    },
    async selectUserType(par) {
      this.selectedType = par;
    },
    async getData() {
      try {
        let checkVariable = {
            email: this.getEmail,
        };
        let data = await this.$apollo.query(checkStep, checkVariable);
        let response = data('**.checkStep');

        this.setEmail(response.email);
        this.selectedType = response.type;
      } catch (error) {
          console.log(error);
      } finally {
          this.loading = false;
      }
    },
    async formSubmit() {
      try {
        this.loading = true;
        let variable = {
          email: this.getEmail,
          type: this.selectedType,
        };
        let data = await this.$apollo.mutate(step3, variable);
        let response = data('**.registerStep3');

        this.SET_TOKEN(response.token.token);
        this.SET_COMPLETE_STEP(3);
        this.SET_USER_TYPE(response.member.type);
        this.setEmail(response.member.email);
        this.$io.refresh();
        await this.$store.dispatch('checkToken', { vm: this, par: { token: response.token.token }, refresh: true });
        if (response.member.type === 'BUYER') {
          return this.$router.push('/register-steps/step-3/inqury-add');
        }
        return this.$router.push('/register-steps/step-3/product-add');
      } catch (e) {
          console.log(e);
      } finally {
          this.loading = false;
      }
    },
  },
  async created() {
    if (this.getStep >= 3) {
      if (this.getUserType === 'BUYER') {
        return this.$router.push('/register-steps/step-3/inqury-add');
      }
      if (this.getUserType === 'SELLER') {
        return this.$router.push('/register-steps/step-3/product-add');
      }
    }
    await this.getData();
  },
  // mounted() {
  //   const idApp = document.getElementById("app");
  //   const oneOfContainers = document.querySelector(".register-step-right-side__extra > div > :nth-child(3)");
    
  //   idApp.style.overflow = "hidden";
  //   oneOfContainers.style.overflow = "hidden";
  // },
  // beforeDestroy() {
  //   const idApp = document.getElementById("app");
  //   const oneOfContainers = document.querySelector(".register-step-right-side__extra > div > :nth-child(3)");
    
  //   idApp.style.overflow = "unset";
  //   oneOfContainers.style.overflow = "unset";
  // }
};
</script>

<style></style>
