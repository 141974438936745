<template>
  <!--jimir3x-->
  <div class="step-footer step-footer--fixed zoom">
    <!--jimir3x-->
    <div class="container-fluid">
      <div class="row justify-content-end">
        <div
          :class="isBack || skipable ? 'justify-content-between' : 'justify-content-end'"
          class="col-12 d-flex align-items-center step-footer-content"
        >
          <p v-if="skipable" @click="clickSkip()" class="skip-btn">{{$t("General.skip")}}</p>
          <router-link
            v-if="isBack"
            class="step-footer-link"
            :to="'/register-steps/' + backStep"
          >
            <span class="link-icon">
              <v-icon :icon="['fac', 'left-arrow']" />
            </span>
            {{$t("buttons.goback")}}
          </router-link>
          
          <div v-if="step !== 1">
              <button v-if="!disabled" @click="submitFooter" class="btn btn-primary">{{$t("buttons.next")}}</button>
              <button v-else class="btn btn-primary" disabled>{{$t("buttons.next")}}</button>
          </div>
          <div v-else>
              <button class="btn btn-primary jumpin" @click="clickJumpin" :disabled="disabled">{{$t("buttons.jumpin")}}</button>
              <button @click="submitFooter" class="btn btn-primary jumpin" :disabled="disabled">{{$t("buttons.adps")}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isBack: false,
    backStep: null,
    disabled: false,
    skipable: false,
  },
  data() {
      return {
           step: null,
      }
  },
  methods: {
    submitFooter() {
      this.$emit('submit', true);
    },
    clickSkip() {
        this.$emit('skip', true);        
    },
    clickJumpin() {
        this.$emit('jumpin', true);        
    },
  },

  created() {
      if (this.$route.path.indexOf('step-1') > -1) {
            this.step = 1;
        } else if (this.$route.path.indexOf('step-2') > -1) {
            this.step = 2;
        } else if (this.$route.path.indexOf('step-3') > -1) {
            this.step = 3;
        } else {
            this.step = 4;
        }
    }
};
</script>
